import { Status } from "../../constans";

export const recipesInitialState = {
  categories: [],
  statuses: {
    categories: Status.IDLE,
  },
  errors: {
    categories: null,
  },
};
