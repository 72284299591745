import { Status } from "../constans";

export const sliceActionMatcher = (
  sliceName,
  action,
  status,
  matcherFn = null
) => {
  return (
    action.type.startsWith(`${sliceName}/`) &&
    action.type.endsWith(`/${status}`) &&
    (!matcherFn || matcherFn(action))
  );
};

export const isActionPending =
  (sliceName, matcherFn = null) =>
  (action) =>
    sliceActionMatcher(sliceName, action, Status.PENDING, matcherFn);

export const isActionFulfilled =
  (sliceName, matcherFn = null) =>
  (action) =>
    sliceActionMatcher(sliceName, action, Status.FULFILLED, matcherFn);

export const isActionRejected =
  (sliceName, matcherFn = null) =>
  (action) =>
    sliceActionMatcher(sliceName, action, Status.REJECTED, matcherFn);
